import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Copycat - Mac OS Copy Snippet App",
  "excerpt": "Welcome to Copycat!"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageHeader = makeShortcode("PageHeader");
const Hero = makeShortcode("Hero");
const Button = makeShortcode("Button");
const Card = makeShortcode("Card");
const Text = makeShortcode("Text");
const Grid = makeShortcode("Grid");
const Cta = makeShortcode("Cta");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <PageHeader heading="Copycat: XXX" lead="This is the Copycat Portfolio Page." mdxType="PageHeader" />
    <Hero heading="Copycat" lead="Copycat tagline goes here." links={<Button mdxType="Button">Learn More</Button>} mdxType="Hero" />
    <Card variant="secondary" mdxType="Card">
   <Text mdxType="Text">Card Text 2</Text>
    </Card>
    <Button variant='secondary' mdxType="Button">
Button Text
    </Button>
    <Grid columns={[1, 2]} gap={[0, 4, 8]} mdxType="Grid">
  <Card heading="Gaeritatis assumenda totam" imageUrl="placeholder.jpg" mdxType="Card">
    Nam dolorum facere ex. Ad provident voluptas doloremque officia. Minus qui cumque.
    </Card>
    <Card heading="Gaeritatis assumenda totam" imageUrl="placeholder.jpg" mdxType="Card">
    Nam dolorum facere ex. Ad provident voluptas doloremque officia. Minus qui cumque.
    </Card>
    <Card heading="Gaeritatis assumenda totam" imageUrl="placeholder.jpg" mdxType="Card">
    Nam dolorum facere ex. Ad provident voluptas doloremque officia. Minus qui cumque.
    </Card>
    </Grid>
    <h1>{`Heading 1`}</h1>
    <h3>{`Header 2`}</h3>
    <h4>{`Header 3`}</h4>
    <ol>
      <li parentName="ol">{`first`}</li>
      <li parentName="ol">{`second`}</li>
      <li parentName="ol">{`third`}</li>
    </ol>
    <Cta heading="Ready to get started?" lead="Read the documentation and start building your site." links={<Button url="https://flex.arshad.io/docs" mdxType="Button">Get Started</Button>} style={{
      bg: "muted"
    }} mdxType="Cta" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      